@import '../_variables';

.cases-list-container {
  position: relative;

  .scroll-to-container {
    position: absolute;
    top: -5rem;
  }
  .empty-table-message-container {
    width: 100%;
    background: white;
    padding: 2rem;
    margin: 0;
    text-align: center;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  #status-column-header {
    border-top-right-radius: 0.5rem;
  }

  .table {
    th,
    td {
      vertical-align: middle;
    }
    tr:nth-child(even) {
      background-color: $contrast;
    }
  }

  @media (max-width: $breakpoint-small) {
    .table {
      th,
      td {
        padding: 1em 0.5rem 1em 1rem;
      }
    }
  }

  @media (min-width: $bootstrap-breakpoint-large) {
    #status-column-header {
      border-top-right-radius: 0;
    }
  }

  .closed-cases-checkbox {
    padding-left: 1rem;
    margin-bottom: 0;
  }
}
