@import '../_variables';

.library-page-container {
    .library-card {
        display: block;
        width: 25rem;
        border-radius: 0.5rem;
        padding: 3.8rem 2rem;
        text-align: start;
        border: none;
        margin-bottom: 2rem;

        .library-card-icon {
            font-size: 1.8rem;
        }

        &:hover, &:focus {
            border: none;
            outline: none;
        }
    }

    @media (max-width: $breakpoint-medium) {
        .library-card {
            width: 50%;
        }
    }

    @media (max-width: $breakpoint-small) {
        .library-card {
            width: 100%;
        }
    }
}