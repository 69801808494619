@import '../_variables';

.bottom-nav {
  background: black;
 
  .navbar-nav {
    width: 100%;

    .nav-link {
      padding-top: 0;
      padding-bottom: 0;
      height: 40px;
      line-height: 40px;
    }
  }

  .fas {
    line-height: 40px;
    display: inline-block;
  }

  .nav-text {
    display: inline-block;
    margin: 0 0 0 10px;
  }

  @media (max-width: $breakpoint-small) {
    .nav-text {
      display: none;
    }
  }
}

/* The container <div> - needed to position the dropup content */
.dropup {
  position: relative;
  display: inline-block;
}

.dropup-btn {
  border: transparent;
  background: transparent;
  color: white;

  &:hover, &:focus {

    border: none;
    outline: none;

    /* Show the dropup menu on hover */
    .dropup-content {
      display: block;
    }
  }
}

/* Dropup content */
.dropup-content {
  list-style: none;
  padding: 0;
  display: block;
  position: absolute;
  bottom: 50px;
  border-radius: 4px;
  background-color: rgb(91, 103, 188);
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  left: 50%;
  margin-left: -90px;
  width: 180px;

  li+li { 
    border-top: 1px solid #4d58a0; 
  }

  /* Dropup triangle */
  &:after {
    content: "";
    display: block;
    border-color: transparent transparent rgb(91, 103, 188) transparent;
    border-style: solid;
    border-width: 10px;  
    position: absolute;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    left: 50%;
    margin-left: -10px;
  }

  /* Links inside the dropup */
  a {
    color: white;
    padding: 12px 16px;
    text-decoration: none;
    display: block;

    /* Change color of dropup links on hover */
    &:hover {
      background-color: #5661b3;
      color: white;
    }
  }

  /* Dropup Button */
  .dropbtn {
    background-color: black;
    color: white;
    padding: 1px;
    font-size: 16px;
    border: none;
  }

  /* The container <div> - needed to position the dropup content */
  .dropup {
    position: relative;
    display: inline-block;
  }

  /* Dropup content (Hidden by Default) */
  .dropup-content {
    display: none;
    position: absolute;
    bottom: 50px;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }

  /* Links inside the dropup */
  .dropup-content a {
    color: white;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  /* Change color of dropup links on hover */
  .dropup-content a:hover {
    background-color: $primary;
  }

  /* Show the dropup menu on hover */
  .dropup:hover .dropup-content {
    display: block;
  }

  /* Change the background color of the dropup button when the dropup content is shown */
  .dropup:hover .dropbtn {
    background-color: black;
  }

}