.react-select-component {
  .react-select-footer {
    border-radius: 0;
    position: -webkit-sticky;
    position: sticky;
    bottom: -4px;
    margin-bottom: -4px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}