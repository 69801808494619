@import '../_variables';

.intake-title-bar {
    box-shadow: 0px 1px 10px $shadow;

    .action-button {
        color: $primary;
        font-size: 1.1rem;
        letter-spacing: 1.18px;
        font-weight: 600;
    }
} 