@import '../_variables';

.new-appointment-container
{
    .close-icon {
        color: $subtitle-grey;
    }

    .drop-down-container {
        z-index: 2;
    }

    @media (max-width: $breakpoint-small) {
        .multi-field-group {
            margin-bottom: 1rem;
        }
    }
}