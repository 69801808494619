@import '../../_variables';

.overview-page-container {

    .form-label {
        font-size: 0.9rem;
        color: $header-grey;
    }

    .tasks-overview-container {
        display: flex;
        flex-flow: column;
    }

    .appointments-overview-container {
        padding-left: 0;
        padding-right: 15px;

        .col-md-12 {
            padding-right: 7.5px;
            padding-left: 7.5px;
        }
    }

    .row {
        margin-right: -7.5px;
        margin-left: -7.5px;
    }

    .cool-grey-text {
        color: $cool-grey;
        font-size: 1.3rem;
    }

    .footnote {
        color: $header-grey;
        font-size: 0.9rem;
    }

    .main-link {
        color: black;
        background-color: transparent;

        &:hover {
            color: $primary;
        }
    }

    @media (max-width: $breakpoint-small) {
        .appointments-overview-container {
            padding-right: 0;
        }
    }
}