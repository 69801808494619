$navbar-linear-gradient: linear-gradient(to right, #4c5bae, #6b78be);
$light-purple-bg: #D1D5ED;

// bootstrap overrides
$primary: #4c5bae;
$secondary: #E8EAF5;
$light: #EFF0F3; // background color
$shadow: #999;
$contrast: #f5f5f5;

$font-family-base: 'proxima-nova';
$headings-font-family: 'proxima-nova-semibold'; // sets font for h1,h2,etc. 

$greyed-text: #b4b5b0;
$text-muted: $greyed-text;
$navbar-dark-color: white;
$subtitle-grey: #9e9e9e;
$header-grey: #797f84;
$cool-grey: #a3abb2;
$pale-grey: #e8eaf6;

$transparent-shadow: rgba(69, 80, 99, 0.12);

$border-radius: .50rem;

$btn-border-radius: 2rem;
$btn-padding-x: 2rem;
$btn-padding-y: .25rem;

$modal-content-border-radius: 0.75rem;

// Breakpoints
$breakpoint-extra-large: 1300px; // extra large desktop and below
$breakpoint-large: 1150px; // large desktop and below
$breakpoint-medium: 970px; // narrow desktop and below
$breakpoint-small: 767px; // tablet and below
$breakpoint-extra-small: 576px; // Small devices (landscape phones, 576px and below)


// Bootstrap breakpoints
$bootstrap-breakpoint-large: 992px;

$container-max-widths: (
  xl: 1300px
) !default;

@import '../node_modules/bootstrap/scss/_functions';
@import '../node_modules/bootstrap/scss/_mixins';
@import '../node_modules/bootstrap/scss/_variables';

