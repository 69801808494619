@import '../_variables';

.user-productivity-report-container {
    .close-icon {
        color: $subtitle-grey;
    }

    .btn-primary {
        color: white;
        border-radius: 0.5rem;

        &:hover, &:focus {
            color: white;
        }
    }
}