@import '../variables';
 
.task-card {
    flex: 1;
    margin-bottom: 1rem !important;
    
    .task {
        margin: .5px;
        padding: .5rem;

        .title-text {
            color: black;
            margin-left: 1rem;
        }

        .row {
            padding: 0;
        }

        .check-box {
            color: $greyed-text;
        }
        
        .inline-circle {
            display: inline;
        }
        
        .date {
            text-align: right;
            padding-left: 0;
            word-break: normal;
            overflow-wrap: normal;
        }

        .client-case-line {
            font-size: small;
            color: $header-grey;
            margin-bottom: -.75rem;
            margin-left: 1rem;
        }

        &:nth-child(even) { 
            background-color: $contrast;

            &:last-child {
                border-bottom-left-radius: $border-radius;
                border-bottom-right-radius: $border-radius;
            }
        } 
    }

    @include media-breakpoint-up(md) {
        position: relative;
        width: 100%;
        max-height: 60rem;
        overflow-y: auto;
    }
}