@import '../_variables';

.dashboard-container {

    .section-header {
        color: $primary;
    }

    .dashboard-section {
        margin-bottom: 2rem;
    }

    .tasks-dashboard-container {
        display: flex;
        flex-flow: column;
    }

    .cool-grey-text {
        color: $cool-grey;
        font-size: 1.3rem;
    }

    .footnote {
        color: $header-grey;
        font-size: 0.9rem;
    }

    .main-link {
        color: black;
        background-color: transparent;

        &:hover {
            color: $primary;
        }
    }
}