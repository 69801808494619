@import '../_variables';

.container-intake-form {

    .step-text {
        margin: 0;
    }
    
    .MuiStepIcon-root.MuiStepIcon-active {
        color: $primary;
    }

    .MuiStepIcon-root.MuiStepIcon-completed {
        color: black;
    }

    @media (max-width: $breakpoint-small) {
        .step-text {
            display: none;
        }
    }
}