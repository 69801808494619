@import './_variables';

// ********************************* Fonts *********************************
@font-face {
  font-family: 'proxima-nova';
  src: local('ProximaNova') , url(./components/fonts/ProximaNova/ProximaNova-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'proxima-nova-bold';
  src: local('ProximaNovaBold'), url(./components/fonts/ProximaNova/ProximaNova-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'proxima-nova-semibold';
  src: local('ProximaNovaSemibold'), url(./components/fonts/ProximaNova/ProximaNova-Semibold.otf) format('opentype');
}

@font-face {
  font-family: 'proxima-nova-light';
  src: local('ProximaNovaLight'), url(./components/fonts/ProximaNova/ProximaNova-Light.otf) format('opentype');
}

// ********************************* Imports *********************************
@import '../node_modules/bootstrap/scss/bootstrap.scss';

// font awesome
@import '../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '../node_modules/@fortawesome/fontawesome-free/scss/regular.scss';
@import '../node_modules/@fortawesome/fontawesome-free/scss/solid.scss';


// ********************************* Global Styles *********************************
.nav-container {
  padding-top: 4.5rem; // to make room for the fixed top nav to not cover content
}

.nav-container-bottom {
  padding-bottom: 4.5rem; // same thing for the bottom nav;
}

.navbar {
  min-height: 4.5rem;
}

.dropup {
  .dropup-content {
    border-radius: $border-radius;
  }
  .dropdown-item {
    border-radius: $border-radius;
  }
}

.mt-6 {
  margin-top: 5rem;
}

select {
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHBvbHlnb24gY2xhc3M9ImNscy0yIiBwb2ludHM9IjEuNDEgNC42NyAyLjQ4IDMuMTggMy41NCA0LjY3IDEuNDEgNC42NyIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIzLjU0IDUuMzMgMi40OCA2LjgyIDEuNDEgNS4zMyAzLjU0IDUuMzMiLz48L3N2Zz4=) no-repeat 95% 50%; 
  -moz-appearance: none; 
	-webkit-appearance: none; 
	appearance: none;
}

.fa-button {
  padding: 0;
  background-color: transparent;
  border: none;
    
  &:focus {
      outline: none !important;
      border: none;
  }
}

@mixin width {
  width: auto !important;
}

@include media-breakpoint-down("sm") {
  .hide-sm-down {
    display: none;
  }
}

@include media-breakpoint-up("md") {
  .show-sm-down {
    display: none;
  }
}

.table {
  border-spacing: 0;

  .column-wrapper {
    max-width: 10rem;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 25px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  a {
    color: #000000;
    text-decoration: underline;

    &:hover {
      color: $primary;
    }
  }

  th, td {
    width:1%;
    padding: 1em 0.5rem 1em 2.5rem;
  }

  thead {
    background-color: $light-purple-bg;

    tr {      
      th:first-child {
        border-top-left-radius: 0.5rem;
      }

      th:last-child {
        border-top-right-radius: 0.5rem;
      }
    }

    th {
      font-family: $headings-font-family;
      font-size: 0.9rem;
      border: none;
    }
  }
}

.radio {
  margin: 0.5rem;
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    + .radio-label {
      &:before {
        content: '';
        background: white;
        border-radius: 100%;
        border: 1px solid darken($light, 25%);
        display: inline-block;
        width: 1.4em;
        height: 1.4em;
        position: relative;
        top: 0em;
        margin-right: 1em; 
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }
    &:checked {
      + .radio-label {
        &:before {
          background-color: $shadow;
          box-shadow: inset 0 0 0 4px $light;
        }
      }
    }
    &:focus {
      + .radio-label {
        &:before {
          outline: none;
          border-color: $shadow;
        }
      }
    }
    &:disabled {
      + .radio-label {
        &:before {
          box-shadow: inset 0 0 0 4px $light;
          border-color: darken($light, 25%);
          background: darken($light, 25%);
        }
      }
    }
    + .radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}

// ********************************* Input Groups *********************************
.input-group {
  input {
    border-right: none;
  }

  .file-upload input[type='file'] {
    display: none;
  }

  .custom-file-label {
    border-style: dashed;
    color: $shadow;
    color: $greyed-text;
    z-index: unset; // not sure why the bootstrap styles set a z-index here, bit it interferes with the date picker
  }
  .custom-file-input ~ .custom-file-label::after {
    content: none;
  }
}

.input-group-append {
  line-height: 1.5rem;
  float: right;
}

.input-group-prepend-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;

  .input-group-prepend {
    line-height: 1.5rem;
    float: left;

    .input-group-text {
      background-color: $primary;
      color: white;
      border-right: none;
      border-top-left-radius: 0.5rem !important;
      border-bottom-left-radius: 0.5rem !important;
      line-height: 1.5rem;
    }
  }

  input {
    border-left: none;
    border-right: 1px solid #ced4da;
  }
}

.input-icon {
  position: relative;
    i {
      position: absolute;
      display: block;
      transform: translate(0, -50%);
      top: 50%;
      pointer-events: none;
      width: 25px;
      text-align: center;
      font-style: normal;
  }
}

.input-icon-left { 
  i {
    left: 0.4rem;
  }

  input {
    padding-left: 2rem;
    padding-right: 0.5rem;
  }
}

.input-icon-right { 
  i {
    right: 0.4rem;
  }

  input {
    padding-left: 0.5rem;
    padding-right: 2rem;
  }
}

.date-picker {
  &.input-group {
    > .react-datepicker-wrapper {
      width: 100%;
      margin-right: -38px;
      //padding-right: 39px;
    }

    > .react-datepicker-popper {
      z-index: 2;
    }

    .react-datepicker__input-container {
      width: 100%;
    
      input {
        border-radius: $input-border-radius;
      }
    }
  }

  .input-group-append {
    z-index: 1;
  }

  .input-group-text {
    background-color: white;
    border-left: none;
    line-height: 1.5rem;
  }
}

.full-width-time-picker {
  .react-datepicker-popper {
    right: 0;

    .react-datepicker {
      width: 100%;

      .react-datepicker__time-container {
        width: 100%;

        .react-datepicker__time-box {
          width: 100%;
        }
      }
    }
  }
}

// ********************************* Intake Forms *********************************
.form-control {
  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #A7AFB6;
    opacity: 1; /* Firefox */
  }

  .dropdown-item {
    border-radius: $border-radius;
  }
}

.input-group-append {
  .input-group-text {
    background-color: white;
    border-left: none;
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
    line-height: 1.5rem;
  }
}

.date-picker {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

// ********************************* Forms *********************************
.form-control {
  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #A7AFB6;
    opacity: 1; /* Firefox */
  }
  
  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #A7AFB6;
  }
  
  &::-ms-input-placeholder { /* Microsoft Edge */
    color: #A7AFB6;
  }

  resize: none;
}

.intake-form, .case-details-form, .new-appointment-form, .new-task-container, .complete-appointment-container, .user-productivity-report-container {

  height: 100%;

  .form-label {
    color: #4E5D78;
    font-size: 0.875rem;
    font-weight: 500;
  }

  .custom-switch {
    padding-top: 0.4rem;
  }

  .button-inline {
    border-style: none;
    margin-top: 45px;
  }

  .add-field-button {
    font-size: 0.9rem;
    font-weight: bold;
    padding: 0 0.3rem;
    letter-spacing: 0.74px;
  }
}

.inline-add-button {
  position: unset;
}

@include media-breakpoint-down("sm") {
  .inline-add-button {
      position: absolute;
      bottom: 0;
  }
}

@include media-breakpoint-up("md") {
  .text-white-md-up {
    color: white !important;
  }
}

// can't figure out how to override this via bootstrap's SCSS
.btn {
  font-weight: 400;
}

.btn-secondary {
  color: $primary;
}

@media print {
  @page {
    margin: 0.3in 1in 0.3in 1in !important
  }
  .text-default-print {
    color: $body-color !important;
  }
}

.text-bold {
  font-family: "proxima-nova-semibold";
}