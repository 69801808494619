@import '../../_variables';

.toolbar-container {
    .bg-filter-off {
        background: transparent;
        border: none;
        padding: 1rem 3rem 1rem 0;

        &:focus {
            outline: none;
        }
    }

    .navigation-buttons {
        button {
            background-color: transparent;
            border: none;
            
            &:focus {
                outline: none !important;
                border: none;
            }
        }
    }

    .selected-tab {
        font-weight: bold;
        color: $primary;
    }

    .plus-icon {
        right: 0;
        bottom: 0;
    }

    @media (max-width: $breakpoint-extra-small) {
        .label-date {
            font-size: 1.2rem;
        }

        .plus-icon {
            font-size: 1.6rem;
        }
    }
}