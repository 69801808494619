@import '../../../_variables';

.ReportsPage-component {

  @media print {
    .collapse {
      display: block;
    }

    .collapsed-icon, 
    .opened-icon { 
      display: none !important; 
    }
  }

  .overview-info-container {
    .form-label {
      font-size: 0.9rem;
      color: $header-grey;
    }
  }

  .report-section {
    margin-bottom: 2rem;
  }

  .details-container {
    .details-section-group-header {
      font-size: 0.9rem;
      color: $header-grey;
    }

    .details-section-group-container {
      margin-bottom: 2rem;
    }

    .accordion .card-header {
      cursor: pointer;

      .collapsed-icon {
        display: none;
      }
      .opened-icon {
        display: unset;
      }
      &.collapsed .collapsed-icon {
        display: unset;
      }
      &.collapsed .opened-icon {
        display: none;
      }
    }

    .details-section-container {
      padding: 1rem 2rem;
      font-size: 0.9rem;

      header {
        font-family: $headings-font-family;
        font-size: 1rem;;
      }

      .form-label {
        color: $primary;
        margin-bottom: 0;
      }

      .form-group,
      .single-line-list {
        margin-bottom: 2rem;
      }


      @include media-breakpoint-up("md") {
        .single-line-list .form-group {
          margin-bottom: 0;
        }
      }

      .case-file-name {
        color: black;
      }
    }
  }
}