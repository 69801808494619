@import '../../../_variables';

.case-details-component {
  background: white;

  .content-background {
    background-color: white;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

  .close-button {
    position: absolute;
    color: $primary;
    z-index: 1020;
    background-color: transparent;
    border: none;
    padding-top: 1rem;

    &:focus {
      outline: none;
    }
  }

  @media only screen and (min-device-width : 768px) 
    and (max-device-width : 1024px)  {
    .close-button {
      margin-left: -.6rem;
    }
  }

  @media only screen and (min-width:320px)
    and (max-device-width : 1024px) {
    .close-button {
      margin-left: -.6rem;
    }
  }

  .fixed-left-container {
    position: fixed;
    left: 0;
    width: 2.2rem;
    padding: 0 0 3rem 0;
    height: 100%;
    background-color: transparent;
    transition: 0.5s;
    z-index: 1020;
  }

  .fixed-left-container-closed {
    background-color: #EFF0F3;
    transition: 1s;
  }

  .side-bar-container {
    position: fixed;
    left: 0;
    width: 16rem;
    padding: 0 0 10rem 0;
    height: 100%;
    background-color: white;
    box-shadow: 0 4px 10px 0 $transparent-shadow;
    transition: 0.5s;
    z-index: 1010;
    overflow-y: scroll;


    .tab-header {
      font-size: 0.8rem;
      font-weight: 600;
      letter-spacing: 0.74px;
      padding: 0.5rem 2.5rem;
      color: #a3abb2;
    }

    .tab-error {
      background-color: #f8d7da !important;
      border: 1px solid #f5c6cb;
    }

    .tab-valid {
      background-color: transparent;
      border: 1px solid transparent;
    }

    .side-bar-background {
      position: fixed;
      height: 100%;
      width: 16rem;
      box-shadow: 0 4px 10px 0 $transparent-shadow;
      z-index: -1;
    }

    ul {
      width: 16rem;
    }
  }

  .side-bar-container-closed {
    margin-left: -16rem;
  }

  .align-center {
    align-self: center;
  }

  .force-inline {
    margin-top: -2.3rem;
  }

  .nav-link {
    padding: 0.5rem 2rem;
    cursor: pointer;
    border-radius: 0;
  }

  .nav-link.active {
    background-color: white;
    color: black;
    font-weight: bold;
  }

  .section-container {
    padding: 2rem 0.5rem 0 0.5rem;
    height: 100%;
    transition: 0.5s;
    margin-left: 16rem;
  }

  .section-container-closed {
    transition: 0.5s;
    margin-left: 2rem;
  }

  @media (max-width: $breakpoint-small) {
    .side-bar-container {
      position: absolute;
    }
  }

  @media (max-width: $breakpoint-small) {
    .section-container {
      position: absolute;
      margin-left: 1.25rem;
    }
  }

  .subsection-divider {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  // trying to get error messages to not have as much margin between them and the controls they are referring to.
  .form-row {
    margin-bottom: 1rem;

    .form-group {
      margin-bottom: 0;
    }
  
    .form-check {
      margin-left: .5rem;
    }
  }
}