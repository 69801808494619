@import '../../../variables';

$discontinued-color: $gray-400;

.MedicationsSection-component {
  .discontinued {
    color: $discontinued-color;

    .form-control,
    .input-group,
    .input-group-text {
      color: $discontinued-color;
      border-color: $discontinued-color;
    }
  }
}