
@import '../../_variables';

.rbc-month-view {
  .calendar-event-component-container {
    .dot {
      display: inline-block;
      height: 12px;
      width: 12px;
      margin-right: 8px;
      background-color: $primary;
      border-radius: 50%;
      vertical-align: top;
    }

    .grayed-img {
      background-color: #9fa7d6;
    }

    .event-content-container {
      padding-right: 20px;

      .event-title {
        width: 100%;
        color: black;
        font-weight: bold;
        font-size: 0.9rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .event-subheading {
        width: 100%;
        color: black;
        font-weight: normal;
        font-size: 0.8rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .grayed-text {
        color: #939393;
      }
    }
  }
}

.rbc-time-view {
  .calendar-event-component-container {
    .dot {
      display: none;
    }

    .event-subheading {
      display: none;
    }
  }
}