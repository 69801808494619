@import '../../../variables';

.tasks-section-container
{
    .empty-table-message-container {
        width: 100%;
        background: white;
        padding: 2rem;
        margin: 0;
        text-align: center;
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
    }

    .table {
        th, td {
            vertical-align: middle;
        }
    }

    @media (max-width: $breakpoint-small) {
        .table {
            th, td {
                padding: 1em 0.5rem 1em 1rem;
            }
              
            .column-wrapper {
                max-width: 8rem;
            }
        }
    }

    .clearfix {
        display: block;
        content: "";
        clear: both;
    }

    .display-only-textbox {
        border: none;
        overflow: hidden;
        background-color: white;
    }

    .task-btn {
        background-color: $primary;
        color: $white;
        font-weight: bold;
        border-radius: 2rem;
        height: 2rem;
        padding: 0.25rem;
        padding-inline-start: 2rem;
        padding-inline-end: 2rem;
    }
}
