@import '../../_variables';

.case-nav-bar {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);

    @media print {
        display: block;

        .close-icon {
            display: none;
        }
    }
    
    .title {
        color: $primary;
        font-size: 1.1rem;
        letter-spacing: 1.18px;
        margin: 0 0 0.2rem 0;
        text-align: center;
    }

    .subtitle {
        color: $subtitle-grey;
        font-size: 0.9rem;
        letter-spacing: 1.18px;
        margin: 0;
        text-align: center;
    }

    .save-button-container {
        width: 7rem;
    }

    .action-button {
        color: $primary;
        font-size: 1.1rem;
        letter-spacing: 1.18px;
        font-weight: 600;
    }

    .close-icon {
        color: $subtitle-grey;
    }

    .shadow {
        box-shadow: 0px 1px 10px #999;
    }
}