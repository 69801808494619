@import '../../_variables';

.rbc-time-view {
    .rbc-event {
        background-color: transparent;
        padding: 0;
        box-shadow: none;
        border: 1px solid white;
        display: unset;
        table-layout: unset;
        top: 0 !important;
        position: unset;
    }

    .rbc-current-time-indicator {
        height: 2px;
    }
    
    .rbc-event-content {
        width: inherit;
        height: inherit;
        left: inherit;

        .rbc-event-custom-week {
            width: inherit;
            height: inherit;
            left: inherit;
            display: table;
            table-layout: fixed;
            background-color: $primary;
            padding: 0.1rem 0 0 0.35rem;
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
            border: 1px solid white;
            position: absolute;
            border-radius: 0.4rem;

            .rbc-event-custom-week-content {
                width: inherit;
                height: inherit;
                left: inherit;
                display: table-cell;
                vertical-align: middle;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    
        .rbc-event-custom-week-past {
            background-color: #9fa7d6;
    
            .rbc-event-custom-week-content-past {
                color: #eaecfa;
            }
        }
    }
}