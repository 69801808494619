@import '../_variables';

.appointment-card-container {
    .card-label {
        color: $cool-grey;
        font-size: 0.85rem;
        margin-bottom: 0.4rem;
        font-weight: 600;
        letter-spacing: 0.07rem;
    }

    .hide-element {
        visibility: hidden;
    }

    .appointment-card-body {
        justify-content: space-between;
        flex-direction: column;
        display: flex;

        .address-line {
            overflow: auto;
            margin-left: 1.5rem;
            display: block;
        }
    }
}