.report-print-page {
    width: 8.5in;
    margin: auto;
    border: 3px solid lightgray;
    background-color: white;
    padding: 0.5in;

    @media print {
      width: unset;
      border: 0;
      margin-top: -4.5rem; // don't need the nav-continaer padding at the top.
      padding-bottom: 0;
    }

  .report {
    font-size: 12px;

    label, .form-label { 
      font-family: "proxima-nova-semibold"; 
      margin-bottom: 0;
    }

    .form-text {
      margin-top: 0;
    }

    hr {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      border: 1px dashed darkgray;
    }

    hr.hard {
      border-width: 0;
      border-bottom: 1px solid black;
    }

    p {
      margin-bottom: 0.5rem;
    }

    section {
      header {
        font-family: "proxima-nova-semibold";
        text-transform: uppercase;
        font-size: 15px;
      }

      margin-bottom: .5rem;
      border-bottom: 1px solid black;
      break-inside: avoid;

      &.allow-page-break {
        break-inside: unset;
      }

      .form-row, .row {
        .form-group {
          margin-bottom: 0.25rem;
        }

      }

    }  

    .single-line-list {
      break-inside: avoid;
      margin-bottom: 1rem;
    }

    .report-list-item {
      break-inside: avoid;
    }
  }
}