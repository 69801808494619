@import '../_variables';

.new-task-container {
  background-color: white;
  padding-top: 2.60rem;
  padding: 2rem;

  .header-title {
      padding-top: 2rem;
  }

  .form-row {
    margin-bottom: 1rem;

    .form-group {
      margin-bottom: 0;
    }

    .form-control {
      border-top-left-radius: .5rem;
      border-bottom-left-radius: .5rem;
    }

    .form-text {
      margin-left: 5px;
    }
  }

  .remove-btn {
    margin-top: 2rem;
    float: right;
  }

  .subsection-divider {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .floating-alert-container {
        
    .floating-alert {
        position: fixed;
        z-index: 1040;
        padding: 1rem;
        right: 0;
        bottom: 2rem;
        left: 0;
        width: 80%;
        margin: 0 auto;
        visibility: hidden;
    }

    .show-message {
        -webkit-animation: fadeinandout 3s linear forwards;
        animation: fadeinandout 3s linear forwards;
    }
    
    @-webkit-keyframes fadeinandout {
        from { 
            opacity: 0;
            visibility: hidden;
        }
        50% { 
            opacity: 1; 
            visibility: unset; 
        }
        to { 
            opacity: 0;
            visibility: hidden; 
        }
    }
      
    @keyframes fadeinandout {
        from { 
            opacity: 0;
            visibility: hidden;
        }
        50% { 
            opacity: 1; 
            visibility: unset; 
        }
        to { 
            opacity: 0;
            visibility: hidden; 
        }
    }
  }
  
  .date-created {
    color: $subtitle-grey;
    font-size: 0.9rem;
    letter-spacing: 1.18px;
    margin: 0;
  }
}