@import '../_variables';

.DashboardWrapper-component{
  .that-nice-purpleish-background-at-the-top {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1000;
    background: $navbar-linear-gradient;

    border-radius: 0 0 25px 25px;
    width: 100%;
    height: 200px;
  }
}