@import '../_variables';

.component-TopNav {
    background: $navbar-linear-gradient;
    min-height: 3.5rem;

    .navbar-header {
        margin: auto 0;
        color: white;
        letter-spacing: 0.05rem;
    }
}
