@import '../_variables';

.calendar-page-container {
    .calendar-component {
        height: 50rem;
        background-color: white;
        padding: 2rem 2rem 3rem 2rem;
        border-radius: 0.8rem;

        .rbc-header {
            color: $subtitle-grey;
            text-transform: uppercase;
            padding: 1rem 0.3rem;
        }

        // styles for the month view
        .rbc-month-view {
            margin-top: 1rem;

            .rbc-now {
                color: $primary;
            }

            .rbc-header {
                border-right: none;
                border-left: none;
                padding: 1rem 0.3rem;
            }

            .rbc-month-row {
                overflow: unset;

                .rbc-off-range-bg {
                    background-image: linear-gradient(45deg, #eff0f3 8.33%, #ffffff 8.33%, #ffffff 50%, #eff0f3 50%, #eff0f3 58.33%, #ffffff 58.33%, #ffffff 100%);
                    background-size: 8.49px 8.49px;
                }

                .rbc-row-content {
                    display: flex;
                    flex-direction: column;
                }
            }

            .rbc-event {
                padding: 0.2rem;
                background-color: transparent;
    
                &:hover, &:focus {
                    outline: none;
                }
            }

            .rbc-show-more {
                padding: 0.2rem 0 0.2rem 0.5rem;
            }
    
            .rbc-date-cell {
                text-align: start;
                padding-left: 0.6rem;
                padding-top: 0.2rem;
            }
        }

        // styles for the week view
        .rbc-time-view {
            border: none;
            margin-top: 1rem;

            .rbc-timeslot-group {
                
                min-height: 90px;
            }
            .rbc-header {
                border: none;
                padding: 0.2rem !important;
            }

            .rbc-time-header {
                border: none;
            }

            .rbc-allday-cell {
                display: none;
            }

            .rbc-events-container {
                margin-right: 0;

                .rbc-event {           
                    &:hover, &:focus {
                        outline: none;
                    }

                    .rbc-event-label {
                        display: none;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }

            .rbc-today {
                background-color: $light;

                a {
                    color: $primary;
                }
            }
        }

        .rbc-time-header-content {
            border-left: none;
        }
    }
}