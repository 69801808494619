@import '../../_variables';

.case-managers-form-container
{
    .empty-table-message-container {
        width: 100%;
        background: white;
        padding: 2rem;
        margin: 0;
        text-align: center;
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
    }

    .btn-select {
        border-radius: 15px;
        padding: 0.25rem 2rem;
    }

    .table {
        th, td {
            vertical-align: middle;
        }
    }

    @media (max-width: $breakpoint-small) {
        .table {
            th, td {
                padding: 1em 0.5rem 1em 1rem;
            }
              
            .column-wrapper {
                max-width: 8rem;
            }
        }
    }
}