@import '../_variables';

.container-case-page {

    button {
        &:focus {
            outline: none;
        }
    }

    .page-link {
        color: black;
        text-decoration: underline;
        background-color: transparent;
        border: none;
        font-weight: bold;
        padding: 0.5rem 0;

        &:hover {
            color: $primary;
        }
    }

    .text-non-linking {
        color: black;
        background-color: transparent;
        border: none;
        padding: 0.5rem 0;
    }

    @include media-breakpoint-up(md) {
        .task-card {
            max-height: 45rem;
        }
    }

    .task-list-item {
        padding-left: 1rem;
        .task-btn {
            margin-top: .25rem;
            margin-right: .5rem;
            margin-left: .5rem;
            border: none;
            color: $greyed-text;
            background-color: white;
        }
    }

    .task-date {
        margin-left: .95rem;
    }

    .floating-alert-container {
        
        .floating-alert {
            position: fixed;
            z-index: 1040;
            padding: 1rem;
            right: 0;
            bottom: 2rem;
            left: 0;
            width: 80%;
            margin: 0 auto;
            visibility: hidden;
        }

        .show-message {
            -webkit-animation: fadeinandout 3s linear forwards;
            animation: fadeinandout 3s linear forwards;
        }
        
        @-webkit-keyframes fadeinandout {
            from { 
                opacity: 0;
                visibility: hidden;
            }
            50% { 
                opacity: 1; 
                visibility: unset; 
            }
            to { 
                opacity: 0;
                visibility: hidden; 
            }
        }
          
        @keyframes fadeinandout {
            from { 
                opacity: 0;
                visibility: hidden;
            }
            50% { 
                opacity: 1; 
                visibility: unset; 
            }
            to { 
                opacity: 0;
                visibility: hidden; 
            }
        }
    }

    .tab-bar-container {
        background-color: $primary;
        position: fixed;
        z-index: 1020;
    }

    .tab-bar-content-container {
        padding: 4.2rem 0 0 0;
        margin: 0;
    }

    .tab-bar {
        list-style-type: none;
        overflow: hidden;
        margin-bottom: 0;
          
        li {    
            float: left;
            margin: 0 2rem 0 1rem;
            font-size: 1.2rem;
            display: block;
            color: white;
            text-align: center;
            padding: 1.3rem 0 0.9rem 0;
            text-decoration: none;

            a {
                color: white;
                text-decoration: none;
            }
        }

        .active-tab-item {
            font-weight: bold;
            border-bottom: 4px solid white;
        }

        .tab-item {
            font-weight: normal;
        }
    }

    .right-side-menu-buttons-container {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
    }

    .right-side-menu-header-container {
        padding: 1.2rem 0.8rem 0 1.5rem;
    }

    .right-side-menu-content-container {
        padding: 0 1.5rem 1rem 1.5rem;
    }

    .right-side-menu-container {
        position: fixed;
        right: 0;
        width: 16rem;
        padding: 0 0 10rem 0;
        height: 100%;
        background-color: white;
        box-shadow: 0 4px 10px 0 $transparent-shadow;
        transition: 0.5s;
        z-index: 1010;
        overflow-y: scroll;

        .right-side-menu-header {
            display: inline-block;
            letter-spacing: 0.5px;
            font-size: 0.9rem;
            margin: 1rem 0 0.8rem;
        }

        .right-side-menu-button {
            float: right;
            padding: 0.8rem;
            margin: 0;
            background-color: transparent;
            border: none;
        }
    }

    .right-side-menu-container-closed {
        margin-right: -16rem;
    }

    .text-details {
        font-size: 0.9rem;
    }

    .text-details-primary {
        color: $primary;
        font-style: italic;
    }

    @media (max-width: $breakpoint-extra-small) {
        .tab-bar {
            li {   
                text-overflow: ellipsis;
                overflow: hidden; 
                margin: 0;
                width: 25%;
            }
        }
    }

    @media (max-width: $breakpoint-small) {

        .right-side-menu-buttons-container {
            display: none;
        }

        .right-side-menu-container {
            margin-right: -16rem;
        }
    }
}